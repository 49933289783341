import { Type } from 'gatsby-cli/lib/structured-errors/types';
import styled, {DefaultTheme} from 'styled-components';


export const textSizeEnum = {
        S1 : '9.4',
        S2 : '6.4',
        S3 : '4.8',
        S4 : '3.6',
        S5 : '2.4',
        S6 : '1.8',
        SP : '1.4',
}


export interface TextProps{
        color ?: keyof DefaultTheme['colors'];
        colorHex ?: string;
        size : keyof typeof textSizeEnum;
        weight?:string;
}
export const H1 = styled.h1<TextProps>`
        font-size : ${(props)=>textSizeEnum[props.size]}em;
        color : ${(props)=>props.color?props.theme.colors[props.color]:props.colorHex?props.colorHex:props.theme.colors.textTitle};
        font-weight : ${props=>props.weight?props.weight : 'normal'};
        margin: 0;
        padding: 0;
        
`;

export const H2 = styled.h2<TextProps>`
        font-size : ${(props)=>textSizeEnum[props.size]}em;
        color : ${(props)=>props.color?props.theme.colors[props.color]:props.colorHex?props.colorHex:props.theme.colors.textTitle};
        font-weight : ${props=>props.weight?props.weight : 'normal'};
        margin: 0;
        padding: 0;
`; 

export const H3 = styled.h3<TextProps>`
         font-size : ${(props)=>textSizeEnum[props.size]}em;
         color : ${(props)=>props.color?props.theme.colors[props.color]:props.colorHex?props.colorHex:props.theme.colors.textTitle};
         font-weight : ${props=>props.weight?props.weight : 'normal'};
        margin: 0;
        padding: 0;

`; 

export const H4 = styled.h4<TextProps>`
        font-size : ${(props)=>textSizeEnum[props.size]}em;
        color : ${(props)=>props.color?props.theme.colors[props.color]:props.colorHex?props.colorHex:props.theme.colors.textTitle};
        font-weight : ${props=>props.weight?props.weight : 'normal'};
        margin: 0;
        padding: 0;
`; 

export const H5 = styled.h5<TextProps>`
        font-size : ${(props)=>textSizeEnum[props.size]}em;
        color : ${(props)=>props.color?props.theme.colors[props.color]:props.colorHex?props.colorHex:props.theme.colors.textTitle};
        font-weight : ${props=>props.weight?props.weight : 'normal'};
        margin: 0;
        padding: 0;
`; 

export const H6 = styled.h6<TextProps>`
        font-size : ${(props)=>textSizeEnum[props.size]}em;
        color : ${(props)=>props.color?props.theme.colors[props.color]:props.colorHex?props.colorHex:props.theme.colors.textTitle};
        font-weight : ${props=>props.weight?props.weight : 'normal'};
        margin: 0;
        padding: 0;
`; 
export const P = styled.p<TextProps>`
        font-size : ${(props)=>textSizeEnum[props.size]}em;
        color : ${(props)=>props.color?props.theme.colors[props.color]:props.colorHex?props.colorHex:props.theme.colors.textP};
        font-weight : ${props=>props.weight?props.weight : 'normal'};

        margin: 0;
        padding: 0;
`; 
export const Ul = styled.ul<TextProps>`
        font-size : ${(props)=>textSizeEnum[props.size]}em;
        color : ${(props)=>props.color?props.theme.colors[props.color]:props.colorHex?props.colorHex:props.theme.colors.textP};
        font-weight : ${props=>props.weight?props.weight : 'normal'};
        margin: 0;
        padding: 0;
`; 